<template>
    <transition name="msgbox">
        <div>
            <div v-if="show" class="overlay" style="z-index: 2001;"></div>
            <div v-if="show" class="msgbox-container" style="z-index: 2002;">
                <div class="box-title">{{title}}</div>
                <div class="content-body">
                    <div>{{content}}</div>
                </div>
                <div class="box-footer">
                    <wx-open-launch-weapp
                      class="launch-btn"
                      @error="handleError"
                      @launch="handleLaunch"
                      username="gh_5fb2f4ef8f10"
                      :path="path"
                    >
                      <script type="text/wxtag-template">
                        <style>
                        .btn {
                            border: 0;
                            width: 100%;
                            height: 48px;
                            background-color: #fff;
                            color: #ee0a24;
                            border-radius: 0 0 16px 16px;
                         }
                        </style>
                        <button class="btn">确定</button>
                      </script>
                    </wx-open-launch-weapp>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
    export default {
        data () {
            return {
                show: false,
                title: '提示',
                content: '',
                confirm: '确定',
                path:''
            }
        },
        method:{
            handleLaunch(e){
                console.log("success", e, e.detail);
            },
            handleError(e){
                console.log("error", e, e.detail);
            },
        }
    }
</script>

<style lang="less" scoped>
    .overlay{
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.7);
    }
    .msgbox-container{
        position: fixed;
        top:45%;
        left:50%;
        width: 90%;
        background: #fff;
        border-radius: 16px;
        transform:translate3d(-50%, -50%, 0);
        .box-title{
            font-weight: 500;
            padding-top: 26px;
            text-align: center;
            line-height: 24px;
            border-radius: 16px;
            color: #323233;
            font-size: 16px;
        }
        .content-body{
            font-size: 16px;
            line-height: 19px;
            max-height: 60vh;
            overflow-y: auto;
            color: #646566;
            div{
                padding: 8px 24px 26px ;
                text-align: center;
                white-space: pre-wrap;
                word-break: break-word;
            }
        }
        .box-footer {
            border-top: 1px solid #cbcbcb;
            .launch-btn{
                width: 90vw;
            }
        }
    }
    .v-enter,.v-leave-to{
        -webkit-transform:  translate(-50%,-50%) scale(0,0);
    }
    .v-enter-active,.v-leave-active{
        -webkit-transition: all .3s;
        transition: all .3s;
    }
    .v-enter-to,.v-leave{
        -webkit-transform:  translate(-50%,-50%) scale(1,1);
    }
</style>
