import { getTokenTicket } from '@/api/wx'
import moment from 'moment'
import { getToken, getTicket, getTokenExpireTime, getTicketExpireTime, setToken, setTicket, setTokenExpireTime, setTicketExpireTime } from '@/utils/wxAuth'

const getDefaultState = () => {
    return {
        token: getToken(),
        tokenExipresIn: getTokenExpireTime(),
        ticket: getTicket(),
        ticketExipresIn: getTicketExpireTime()
    }
}

const state = getDefaultState()

const mutations = {
    SET_TOKEN: (state, token) => {
        state.token = token
    },
    SET_TOKEN_EXIPRES_IN: (state, tokenExipresIn) => {
        state.tokenExipresIn = tokenExipresIn
    },
    SET_TICKET: (state, ticket) => {
        state.ticket = ticket
    },
    SET_TICKET_EXIPRES_IN: (state, ticketExipresIn) => {
        state.ticketExipresIn = ticketExipresIn
    },
}

const actions = {
    getTokenTicket(store) {
        const { commit } = store
        return new Promise((resolve, reject) => {
            getTokenTicket().then(response => {
                if (response.data.code == 0){
                    commit('SET_TOKEN', response.data.data.access_token)
                    commit('SET_TOKEN_EXIPRES_IN', moment().add(response.data.data.token_expires_in,'s').format('X'))
                    commit('SET_TICKET', response.data.data.ticket)
                    commit('SET_TICKET_EXIPRES_IN', moment().add(response.data.data.ticket_expires_in,'s').format('X'))
                    setToken(response.data.data.access_token)
                    setTokenExpireTime(moment().add(response.data.data.token_expires_in,'s').format('X'))
                    setTicket(response.data.data.ticket)
                    setTicketExpireTime(moment().add(response.data.data.ticket_expires_in,'s').format('X'))
                }else {
                    console.log("code:"+response.data.code+", message:"+response.data.message)
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
