<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>

export default {
  name: 'App'
}
</script>

<style>
  body {
    font-size: 16px;
    /*background-color: #f8f8f8;*/
    -webkit-font-smoothing: antialiased;
  }
</style>
