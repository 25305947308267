import router from './router'
import store from './store'

router.beforeEach(async(to, from, next) => {
    if (to.path === '/wxlogin'){
        next()
    }else {
        if (store.getters.openid == '') {
            store.dispatch('user/getInfo', to.query.code).then(() => {
                store.dispatch('device/getInfo', store.getters.openid).then(() => {
                    if (to.path != "/homepage" && store.getters.deviceId == ''){
                        next('/homepage')
                    }else {
                        next()
                    }
                }).catch((error) => {
                    console.log("获取deviceId失败")
                    alert(error)
                })
            }).catch((error) => {
                console.log("获取openid失败")
                alert(error)
            })
        }else {
            next()
        }
    }
})
