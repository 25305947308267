import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import device from './modules/device'
import wx from './modules/wx'
import getters from './getters'

Vue.use(Vuex)

const store = new Vuex.Store({
    modules: {
        user,
        device,
        wx
    },
    getters
})

export default store


