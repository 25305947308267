import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/permission'
import 'amfe-flexible'

Vue.config.productionTip = false
Vue.config.ignoredElements = ['wx-open-launch-weapp']
import msgBoxComponent from './components/popup/index'

let msgBox = (options={}) => {
  let msgBoxConstructor = Vue.extend(msgBoxComponent);
  let instance= new msgBoxConstructor({
    el: document.createElement('div')
  });
  document.body.appendChild(instance.$el);
  // 将单个 confirm instance 的配置合并到默认值中
  Object.assign(instance.$data, options);
  return new Promise((resolve, reject)=>{
    instance.show = true;
    let successBtn = instance.successBtn;
    let cancelBtn = instance.cancelBtn;
    instance.successBtn = () => {
      resolve();
      successBtn();
    }
    instance.cancelBtn = () => {
      reject();
      cancelBtn();
    }
  });
}
// //添加全局API
Vue.prototype.$msgbox = msgBox

new Vue({
  el: '#app',
  store,
  router,
  render: h => h(App)
})
