import { getInfo } from '@/api/wx'

const state = {
    openid: '',
    name: '',
    imgUrl: '',
    isAdmin: false,
    role: 0
}

const mutations = {
    SET_OPENID: (state, openid) => {
        state.openid = openid
    },
    SET_NAME: (state, name) => {
        state.name = name
    },
    SET_IMG_URL: (state, imgUrl) => {
        state.imgUrl = imgUrl
    },
    SET_IS_ADMIN: (state, isAdmin) => {
        state.isAdmin = isAdmin
    },
    SET_ROLE: (state, role) => {
        state.role = role
    },
}

const actions = {
    // get info
    getInfo(store, code){
        const { commit } = store
        return new Promise((resolve, reject) => {
            getInfo({code: code}).then(response => {
                if (response.data.code == 0){
                    commit('SET_OPENID', response.data.data.openid)
                    commit('SET_NAME', response.data.data.name)
                    commit('SET_IMG_URL', response.data.data.imgUrl)
                }else {
                    console.log("code:"+response.data.code+", message:"+response.data.message)
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
