import Vue from 'vue';
import Router from 'vue-router';

Vue.use(Router);

const routes = [
    {
        path: '*',
        redirect: '/homepage'
    },
    {
        name: 'homepage',
        component: () => import('@/views/homepage'),
        meta: {
            title: '我的主页'
        }
    },
    {
        name: 'family',
        component: () => import('@/views/family'),
        meta: {
            title: '家庭成员管理'
        }
    },
    {
        name: 'studyreport',
        component: () => import('@/views/studyreport'),
        meta: {
            title: '学习报告'
        }
    },
    {
        name: 'wxlogin',
        component: () => import('@/views/wxlogin'),
        meta: {
            title: '小程序登录'
        }
    }
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({
    mode: 'history',
    routes
});

router.beforeEach((to, from, next) => {
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
    next();
});

export default router
