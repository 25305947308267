const getters = {
    openid: state => state.user.openid,
    isAdmin: state => state.user.isAdmin,
    deviceId: state => state.device.deviceId,
    user: state => state.user,
    token: state => state.wx.token,
    tokenExipresIn: state => state.wx.tokenExipresIn,
    ticket: state => state.wx.ticket,
    ticketExipresIn: state => state.wx.ticketExipresIn
}

export default getters
