import { getDevices } from '@/api/wx'

const state = {
    deviceId: ''
}

const mutations = {
    SET_DEVICEID: (state, deviceId) => {
        state.deviceId = deviceId
    }
}

const actions = {
    // get device info
    getInfo(store, openid) {
        const { commit, rootState } = store

        return new Promise((resolve, reject) => {
            getDevices({openid: openid}).then(response => {
                if (response.data.code == 0){
                    var devices = response.data.data.list
                    if (devices != null && devices != ''){
                        // 目前一个用户只绑定一个设备，所以直接取第一个元素
                        commit('SET_DEVICEID', devices[0].deviceId)
                        var users = response.data.data.list[0].memberList;
                        for(var index in users) {
                            // 遍历users数组判断当前用户
                            if(rootState.user.openid == users[index].openid){
                                commit('user/SET_NAME', users[index].name, {root: true})
                                commit('user/SET_IMG_URL', users[index].imgUrl, {root: true})
                                commit('user/SET_IS_ADMIN', users[index].isAdmin, {root: true})
                                commit('user/SET_ROLE', users[index].role, {root: true})
                                break;
                            }
                        }
                    }
                }else {
                    console.log("code:"+response.data.code+", message:"+response.data.message)
                }
                resolve()
            }).catch(error => {
                reject(error)
            })
        })
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
