import Cookies from 'js-cookie'
import moment from 'moment'

const TokenKey = 'wx_access_token' // token
const ticketKey = 'ticket' // ticket
const tokenExpireTime = 'wx_token_expires_in' // token过期时间
const ticketExpireTime = 'ticket_expires_in' // ticket过期时间

export function getToken() {
    return Cookies.get(TokenKey)
}

export function setToken(token) {
    return Cookies.set(TokenKey, token)
}

export function removeToken() {
    return Cookies.remove(TokenKey)
}

export function getTicket() {
    return Cookies.get(ticketKey)
}

export function setTicket(ticket) {
    return Cookies.set(ticketKey, ticket)
}

export function removeTicket() {
    return Cookies.remove(ticketKey)
}

export function getTokenExpireTime() {
    return Cookies.get(tokenExpireTime)
}

export function setTokenExpireTime(time) {
    return Cookies.set(tokenExpireTime, time)
}

export function removeTokenExpireTime() {
    return Cookies.remove(tokenExpireTime)
}

export function isTokenExpired() {
    // 获取token过期时间
    let expiredTime  = getTokenExpireTime()
    // 获取本地时间
    let nowTime = moment().format('X')
    // 如果 < 10分钟，则说明即将过期
    return (expiredTime - nowTime) < 10*60
}

export function getTicketExpireTime() {
    return Cookies.get(ticketExpireTime)
}

export function setTicketExpireTime(time) {
    return Cookies.set(ticketExpireTime, time)
}

export function removeTicketExpireTime() {
    return Cookies.remove(ticketExpireTime)
}

export function isTicketExpired() {
    // 获取ticket过期时间
    let expiredTime  = getTicketExpireTime()
    // 获取本地时间
    let nowTime = moment().format('X')
    // 如果 < 10分钟，则说明即将过期
    return (expiredTime - nowTime) < 10*60
}
