import request from '@/utils/request'

// 获取公众号openid等信息
export function getInfo(data) {
    return request({
        url: '/miniProgLogin',
        method: 'post',
        data
    })
}

// 获取设备列表
export function getDevices(data) {
    return request({
        url: '/devices',
        method: 'post',
        data
    })
}

// 获取家庭成员
export function getMembers(data) {
    return request({
        url: '/members',
        method: 'post',
        data
    })
}

// 获取学习报告
export function getStatistics(data) {
    return request({
        url: '/statistics',
        method: 'post',
        data
    })
}

// 设置家庭成员身份
export function changeRole(data) {
    return request({
        url: '/changeRole',
        method: 'post',
        data
    })
}

// 解绑设备
export function unBindDevice(data) {
    return request({
        url: '/unbind',
        method: 'post',
        data
    })
}

// 获取token
export function getTokenTicket() {
    return request({
        url: '/tokenTicket',
        method: 'get'
    })
}

// 获取签名
export function getSignature(data) {
    return request({
        url: '/signature',
        method: 'post',
        data
    })
}
